<template>

  <div :class="{'uploader': true, 'gloading': is_loading}">
    Upload cover
    <input type="file" ref="file" accept=".jpg, .jpeg, .png" @change="changeFiles">
  </div>

</template>


<script setup>

const { $api, $popup } = useNuxtApp();

const emits = defineEmits([
  'changed',
  'uploaded',
  'errored'
])

const props = defineProps({
  diary_id: {
    type: Number,
    required: true
  }
})

const files = ref([])
const file = ref(null)
const thumbs = ref([])
const is_loading = ref(false)

const randomString = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const changeFiles = () => {  
  console.log(file.value.files);
  for( var i = 0; i < file.value.files.length; i++ ){                
    file.value.files[i].uploading_id = randomString(10);
    files.value.push(file.value.files[i])        
    emits('changed', file.value.files[i].uploading_id); 
  }
  submitFiles();
}

const uploadFile = (file) => { 
  
  let formData = new FormData();  
  // formData.append('diary_id', props.diary_id); 
  formData.append('file', file, file.name);
  if(props.diary_id){
    formData.append('diary_id', props.diary_id); 
  }

  is_loading.value = true;
 
  $api.postUploaderDiaryCover(formData) 
  .then(response => { 
    is_loading.value = false;
    uploadedEvent(file.uploading_id, response.data.value.data);
  })
  .catch(error => {
    is_loading.value = false;
    $popup.error('Error uploading file');
    errorEvent(file.uploading_id);
  });

  // this.uploadedEvent(file.uploading_id, dt);
}

const submitFiles = () => {  
  for(var x in files.value){        
    uploadFile(files.value[x]);
  }
  files.value = [];
}

const uploadedEvent = (uploading_id, data) => {      
  emits('uploaded', data, uploading_id); 
}

const errorEvent = (uploading_id, data) => {      
  emits('errored', uploading_id); 
}








// export default {
//   components: {         
//   },
//   props: [
//     'diary_id'
//   ],
//   data() {
//     return {      
//       files: [],
//       thumbs: [],
//     };
//   },
//   methods: {

//     randomString(length) {
//       var result           = '';
//       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//       var charactersLength = characters.length;
//       for ( var i = 0; i < length; i++ ) {
//          result += characters.charAt(Math.floor(Math.random() * charactersLength));
//       }
//       return result;
//     },
//     changeFiles(){  
//       for( var i = 0; i < this.$refs.file.files.length; i++ ){                
//         this.$refs.file.files[i].uploading_id = this.randomString(10);
//         this.files.push(this.$refs.file.files[i])        
//         this.$emit('changed', this.$refs.file.files[i].uploading_id); 
//       }
//       this.submitFiles();
//     },
//     uploadFile(file){ 
    
//       let formData = new FormData();  
//       formData.append('diary_id', this.diary_id); 
//       formData.append('file', file, file.name); 
   
//       this.$api.postUploaderDiaryCover(formData) 
//       .then(response => { 
//         this.uploadedEvent(file.uploading_id, response.data.value.data);
//       })
//       .catch(error => {
//         this.errorEvent(file.uploading_id);
//       });

//       // this.uploadedEvent(file.uploading_id, dt);
//      },
//     submitFiles(){  
//       for(var x in this.files){        
//         this.uploadFile(this.files[x]);
//       }
//       this.files = [];
//     },
//     uploadedEvent(uploading_id, data){      
//       this.$emit('uploaded', data, uploading_id); 
//     },
//     errorEvent(uploading_id, data){      
//       this.$emit('errored', uploading_id); 
//     },
//   }
// }
</script>
   

<style scoped>

.uploader{
  background-color: #767676;
  color: #FFFFFF;
  display: inline-block;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 20px;
  position: relative;
  text-align: center;
}
input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';  
  direction: ltr;
  cursor: pointer;
  width: 100%;
  height: 100%;
} 


</style>
